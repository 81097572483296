.rmdp-range {
  background-color: #f53304 !important;
  box-shadow: none;
}

.drawer-main-search div:nth-of-type(3) > div {
  max-width: 100%;
}

/* html body .filter-modal .MuiDialog-container {
  transition: none !important;
} */
.filter-modal .MuiPaper-root {
  border: 1px solid #999999;
  box-shadow: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.guest-dropdown-height {
  max-height: 500px;
  overflow: auto;
}

::selection {
  color: #fff;
  background: #e10f3f;
}

.package-card-img {
  transition: transform 0.3s;
  width: 100%;
  max-width: 100%;
  height: auto;
}

.package-card-img:hover {
  transform: scale(1.1);
  cursor: pointer;
}

.disabled {
  color: rgba(0, 0, 0, 0.38);
  cursor: pointer !important;
  pointer-events: none;
}

.disabled svg {
  fill: #00000033 !important;
}

.disabled path {
  fill: #00000033 !important;
}

.custom-dropdown {
  width: 100%;
  position: relative;
  margin-top: 10px;
  outline: 0;
}

.custom-dropdown .dropdown-toggle span {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  /* max-width: 240px; */
  /* max-width: 146px; */
  max-width: calc(100% - 40px);
  margin-left: 5px;
  text-align: left;
  margin-top: 2px;
}

.custom-dropdown .dropdown-toggle::after {
  display: none;
}

.custom-dropdown .dropdown-toggle {
  position: relative;
  font-size: 14px;
  /* padding: 10px 8px; */
  padding: 10px 8px;
  /* padding-right: 20px; */
  width: 100%;
  background-color: #ffffff;
  max-height: 52.8px;
  height: 100%;
  min-height: 47.8px;
}

.custom-dropdown .dropdown-toggle .drop-icno {
  max-width: 24px;
  height: auto;
  width: 22px;
  /* font-size: 1.5rem; */
}

.custom-dropdown .dropdown-toggle .drop-icnos {
  max-width: 24px;
  height: 20px;
  width: 20px;
  /* font-size: 1.5rem; */
}

.custom-dropdown .dropdown-toggle .drop-icnos-class {
  max-width: 24px;
  height: 17px;
  width: 13px;
  /* font-size: 1.5rem; */
}

.custom-dropdown .dropdown-toggle .down-arrow {
  position: absolute;
  top: 50%;
  right: 7px;
  transform: translateY(-50%) rotate(0);
  transition: all 0.5s ease;
}

.custom-dropdown .dropdown-toggle .down-arrow.rotate {
  transform: translateY(-50%) rotate(180deg);
}

.custom-dropdown .dropdown-menu {
  /* width: max-content; */
  /* width: 100%; */
  /* width: "auto"; */
  padding: 22px 8px;
  overflow: auto;
  margin-top: 4px;
  /* max-height: 321px; */
  /* max-width: 318px; */
  /* width: 321px; */
  left: auto;
  right: 0px;
  /* min-width: 199px; */
}
.custom-dropdown .guest-dropdown {
  width: max-content !important;

  padding: 22px 8px;
  overflow: auto;
  margin-top: 4px;
  max-height: 321px;
  max-width: 318px;
  width: 321px;
  left: auto;
  right: 0px;
  /* min-width: 199px; */
}

.custom-dropdown .dropdown-menu .dropdown-item {
  padding: 5px 10px;
  border-radius: 5px;
  line-height: 1.3;
  font-size: 14px;
  text-decoration: none;
  /* white-space: break-spaces; */
}

label.custom-dropdown .dropdown-menu .dropdown-item.active {
  background-color: rgba(255, 51, 0, 0.125) !important;
}

.custom-dropdown .dropdown-menu .dropdown-item.active,
.custom-dropdown .dropdown-menu .dropdown-item:active {
  background-color: transparent;
  color: #000;
}

.custom-dropdown .dropdown-menu .dropdown-item:focus,
.custom-dropdown .dropdown-menu .dropdown-item:hover {
  background: rgba(255, 51, 0, 0.125);
  color: #000;
}

/* .custom-dropdown .dropdown-menu {
  width: 100%;
  padding: 22px 8px;
  max-height: 300px;
  overflow: auto;
} */
.dropdown-menu {
  overflow-x: hidden;
  position: absolute;
  z-index: 1000;
  display: none;
  /* min-width: 221px; */
  width: 100%;
  padding: 22px 8px;
  margin: 0;
  font-size: 16px;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #999999;
  border-radius: 6px;
}
.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

label.custom-dropdown {
  margin-bottom: 0;
}
.dropdown-menu.show {
  display: block;
}

.text-bold {
  font-size: 14px;
  line-height: 16px;
  color: #000;
  font-weight: bold;
  font-family: "Raleway-bold";
  margin-left: 1px;
}

@media (max-width: 565px) {
  .dropdown-menu {
    /* min-width: calc(100% + 50px); */
    overflow-x: hidden;
  }
}

/* Stepper Styles */

.stepper-last-class .activeBox::before {
  display: none;
}

.active-content {
  position: relative;
}

.active-content:before {
  content: "";
  position: absolute;
  top: 0;
  right: -35px;
  border-style: solid;
  border-width: 35px 0 37px 35px;
  border-color: transparent transparent transparent #d9d9d9;
}

.MuiAutocomplete-option {
  display: block !important;
}

@media (max-width: 991px) {
  .active-content:before {
    right: -25px;
    border-width: 25px 0 27px 25px;
  }
}

@media (max-width: 530px) {
  .active-content:before {
    right: -20px;
    border-width: 35px 0 37px 20px;
  }
}

@media (max-width: 370px) {
  .active-content:before {
    right: -21px;
    /* border-width: 31px 0 33px 22px; */
    border-width: 35px 0 37px 22px;
  }
}

@media (max-width: 350px) {
  .active-content:before {
    right: -14px;
    border-width: 36px 0 36px 15px;
  }
}

:focus-visible {
  outline: none !important;
  /* outline: 1px solid #000 !important;
  outline-style:outset;
  outline-width:unset; */
  border-radius: 5px;
}

li:focus-visible,
button:focus-visible,
ul:focus-visible {
  outline: none !important;
}

.actions button {
  box-shadow: none;
}

.actions button:focus {
  box-shadow: none !important;
}

.platinum-icon-class {
  border-color: #898064 !important;
  color: #898064 !important;
}
.primary-icon-class {
  border-color: #e10f3f !important;
  color: #e10f3f !important;
}
.platinum-icon-class .swal2-x-mark-line-left{
  border-color: #898064 !important;
  background: #898064 !important;
}
.platinum-icon-class .swal2-x-mark-line-right{
  border-color: #898064 !important;
  background: #898064 !important;
}

.primary-icon-class .swal2-x-mark-line-left{
  border-color: #e10f3f !important;
  background: #e10f3f !important;
}
.primary-icon-class .swal2-x-mark-line-right{
  border-color: #e10f3f !important;
  background: #e10f3f !important;
}
.primary-cancel-button {
  background-color: transparent !important;
  border: 1px solid #e10f3f !important;
  color: #e10f3f !important;
}

.platinum-cancel-button {
  background-color: transparent !important;
  border: 1px solid #898064 !important;
  color: #898064 !important;
}
div:where(.swal2-container) button:where(.swal2-styled).swal2-cancel {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  border: none;
  padding: 14px 23px;
}
div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  border: none;
  padding: 14px 23px;
}

.swal2-error {
  width: 5em !important;
  height: 5em !important;
}

div:where(.swal2-container)
  div:where(.swal2-actions):not(.swal2-loading)
  .swal2-styled:hover {
  background-image: none !important;
}

div:where(.swal2-container){
  z-index: 10000000 !important;
}

div:where(.swal2-container) div:where(.swal2-popup) {
  width: 38em !important;
  padding: 30px !important;
}
div:where(.swal2-icon) {
  margin: 0.5em auto.5em !important;
  width: 4em !important;
  height: 4em !important;
}
div:where(.swal2-container) h2:where(.swal2-title) {
  font-size: 28px !important;
}
@media (max-width: 600px) {
  .custom-dropdown .dropdown-menu-sort {
    max-width: 200px !important;
    width: max-content;
  }
}
@media (max-width: 600px) {
  div:where(.swal2-container) h2:where(.swal2-title) {
    font-size: 20px !important;
  }
  div:where(.swal2-container) div:where(.swal2-popup) {
    padding: 10px !important;
  }
  div:where(.swal2-container) .swal2-html-container {
    font-size: 14px !important;
  }
  div:where(.swal2-icon) {
    width: 3em !important;
    height: 3em !important;
  }
  div:where(.swal2-icon) .swal2-icon-content {
    font-size: 40px !important;
  }
  div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
    padding: 10px 20px !important;
    font-size: 14px !important;
  }
  div:where(.swal2-container) button:where(.swal2-styled).swal2-cancel {
    padding: 10px 20px !important;
    font-size: 14px !important;
  }
}

@media (max-width: 1199px) {
  .custom-dropdown .guest-dropdown{
    right: unset;
  }
}

@media (max-width: 767px) {
  .custom-dropdown .guest-dropdown{
    right: 0;
  }
}