* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "El Messiri", sans-serif;
  font-family: "Raleway", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  padding: 0 !important;
}

/* @font-face {
  font-family: "El Messiri-bold";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local("El Messiri Medium"), local("ElMessiri-Medium"),
    url(../src/assets/fonts/ElMessiri-Medium.woff) format("woff"),
    url(../src/assets/fonts/ElMessiri-Medium.woff2) format("woff2");
  unicode-range: U+00??, U+0131, U+0152-0153, U+02bb-02bc, U+02c6, U+02da,
    U+02dc, U+2000-206f, U+2074, U+20ac, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+feff, U+fffd;
} */

@font-face {
  font-family: "ElMessiri-SemiBold";
  src: url(../src/assets/fonts/ElMessiri-SemiBold.woff2) format("woff2"),
    url(../src/assets/fonts/ElMessiri-SemiBold.woff) format("woff"),
    url(../src/assets/fonts/ElMessiri-SemiBold.otf) format("otf"),
    url(../src/assets/fonts/ElMessiri-SemiBold.svg) format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

/* @font-face {
  font-family: Raleway;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Raleway"), local("Raleway-Regular"),
    url(../src/assets/fonts/Raleway-Regular.woff) format("woff"),
    url(../src/assets/fonts/Raleway-Regular.woff2) format("woff2");
  unicode-range: U+00??, U+0131, U+0152-0153, U+02bb-02bc, U+02c6, U+02da,
    U+02dc, U+2000-206f, U+2074, U+20ac, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+feff, U+fffd;
} */

/* @font-face {
  font-family: Raleway;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Raleway Bold"), local("Raleway-Bold"),
    url(../src/assets/fonts/Raleway-Regular.woff) format("woff"),
    url(../src/assets/fonts/Raleway-Regular.woff2) format("woff2");
  unicode-range: U+00??, U+0131, U+0152-0153, U+02bb-02bc, U+02c6, U+02da,
    U+02dc, U+2000-206f, U+2074, U+20ac, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+feff, U+fffd;
}

@font-face {
  font-family: Raleway;
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local("Raleway Black"), local("Raleway-Black"),
    url(../src/assets/fonts/Raleway-Regular.woff) format("woff"),
    url(../src/assets/fonts/Raleway-Regular.woff2) format("woff2");
  unicode-range: U+00??, U+0131, U+0152-0153, U+02bb-02bc, U+02c6, U+02da,
    U+02dc, U+2000-206f, U+2074, U+20ac, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+feff, U+fffd;
} */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.add-secondary-colour input:-webkit-autofill,
.add-secondary-colour input:-webkit-autofill:hover,
.add-secondary-colour input:-webkit-autofill:focus,
.add-secondary-colour input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #f9f9f9 inset !important;
}

textarea {
  border-width: 1px;
  border-color: #999999;
  border-radius: 5px;
  padding: 5px;
}

textarea:hover,
input:hover,
textarea:active,
input:active,
textarea:focus,
input:focus {
  outline: 0px !important;
}

input::webkit-input-placeholder {
  background-color: transparent;
}

.rmdp-day.rmdp-selected span:not(.highlight) {
  background-color: #c4c4c4 !important;
}

.css-pxocdv-MuiModal-root-MuiPopover-root-MuiMenu-root .MuiMenu-paper {
  /* max-width: 278px; */
  /* width: 100%; */
}

.package-card-chip {
  font-size: 0.75rem !important;
  display: inline-flex !important;
  background-color: #e10f3f;
  color: #fff !important;
  padding: 0.3125rem !important;
  border-radius: 2px !important;
  font-family: "Raleway";
  font-size: 12px !important;
  height: 28px;
  /* margin-bottom: .625rem !important; */
  /* -webkit-align-self: flex-start !important;
  -ms-flex-item-align: start !important;
  align-self: flex-start !important; */
}

.MuiTooltip-tooltip {
  font-size: 12px !important;
  line-height: 1.2;
  padding: 10px;
}

@media only screen and (max-width: 599px) {

  .datepicker,
  .datepicker div {
    width: 100%;
  }
}

.gm-style-iw-ch {
  padding-top: 0 !important;
}