.btn {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

 a{
    text-decoration: unset;
}

.btn-primary {
    /* margin: 10px auto; */
    height: 40px;
    cursor: "pointer";
    font-weight: 700;
    color: #fff;
    background-color: #999999;
    border-color: #999999;
}

.btn-primary:hover {
    color: #fff;
    background-color: #999999;
    border-color: #999999;
}

