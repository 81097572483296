.gm-ui-hover-effect {
  display: none !important;
}

.gm-style-iw.gm-style-iw-c {
  background-color: white !important;
  width: fit-content !important;
  /* padding: 4px 14px !important; */
  border: 3px solid black !important;
  border-radius: 50px !important;
  /* z-index: 10 !important; */
  overflow: unset;
  cursor: pointer;
  padding: 0;
  z-index: -1;
}

.gm-style-iw-tc::after {
  display: none;
}

/* .gm-style-iw.gm-style-iw-c::after {
  content: "" !important;
  content: "" !important;
  height: 15px !important;
  width: 15px !important;
  position: absolute !important;
  background-color: pink !important;
  bottom: -18px !important;
  left: 50% !important;
  transform: rotate(45deg) translateX(-50%) !important;
  border: 0 !important;
  border-bottom: #000000 solid 3px !important;
  border-right: #000000 solid 3px !important;
  z-index: -1;
} */
.gm-style .gm-style-iw-tc {
  z-index: -10;
}

.gm-style-iw-d {
  overflow: unset !important;
}

.gm-style-iw.gm-style-iw-c.myStyle,
.gm-style-iw.gm-style-iw-c.myStyle .down-arrow {
  border-color: #e10f3f !important;
}
.gm-style-iw.gm-style-iw-c.platinumStyle,
.gm-style-iw.gm-style-iw-c.platinumStyle .down-arrow {
  border-color: #898064 !important;
}

.gm-style-iw.gm-style-iw-c.myStyle::after {
  border-bottom: #e10f3f solid 3px !important;
  border-right: #e10f3f solid 3px !important;
}
.gm-style-iw.gm-style-iw-c.platinumStyle::after {
  border-bottom: #898064 solid 3px !important;
  border-right: #898064 solid 3px !important;
}

.gm-style-iw.gm-style-iw-c.myStyle h1 {
  color: #e10f3f;
}

/* Outlined-light-red */
.gm-style-iw.gm-style-iw-c.myStyle.outlined-light-red {
  border: 3px solid #ff633c !important;
  color: white;
  outline: 3px solid #ffffff !important;
}

.gm-style-iw.gm-style-iw-c.myStyle.outlined-light-platinum {
  border: 3px solid #898064 !important;
  color: white;
  outline: 3px solid #ffffff !important;
}


.outlined-light-red::before {
  content: "";
  display: block;
  position: absolute;
  left: calc(50% + 3px);
  transform: translateX(-50%) rotate(45deg);
  border-right: 3px solid #fff;
  border-bottom: 3px solid #fff;
  bottom: -16px;
  z-index: 1;
  width: 17px;
  height: 17px;
}

/* Light border */
.gm-style-iw.gm-style-iw-c.myStyle.outlined-light-red::after {
  border-bottom: #ff633c solid 3px !important;
  border-right: #ff633c solid 3px !important;
}

.gm-style-iw.gm-style-iw-c.light-border.myStyle {
  border: 3px solid #ff633c !important;
}

.gm-style-iw.gm-style-iw-c.myStyle.light-border::after {
  border-bottom: #ff633c solid 3px !important;
  border-right: #ff633c solid 3px !important;
}

.gm-style-iw.gm-style-iw-c.platinumStyle.outlined-light-platinum::after {
  border-bottom: #898064 solid 3px !important;
  border-right: #898064 solid 3px !important;
}

.gm-style-iw.gm-style-iw-c.light-border.platinumStyle {
  border: 3px solid #898064 !important;
}

.gm-style-iw.gm-style-iw-c.platinumStyle.light-border::after {
  border-bottom: #898064 solid 3px !important;
  border-right: #898064 solid 3px !important;
}

.gm-style-iw-ch {
  padding-top: 0 !important;
}
