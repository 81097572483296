/* @font-face {
  font-family: "ElMessiri-Medium";
  src: url("../fonts/ElMessiri-Medium.eot");
  src: local("☺"), url("../fonts/ElMessiri-Medium.woff") format("woff"),
    url("../fonts/ElMessiri-Medium.ttf") format("truetype"),
    url("../fonts/ElMessiri-Medium.svg") format("svg");
  font-weight: normal;
  font-style: normal;
} */
@font-face {
  font-family: "ElMessiri-SemiBold";
  src: url("../fonts/ElMessiri-SemiBold.eot");
  src: local("☺"), url("../fonts/ElMessiri-SemiBold.woff") format("woff"),
    url("../fonts/ElMessiri-SemiBold.ttf") format("truetype"),
    url("../fonts/ElMessiri-SemiBold.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

/* @import url("https://fonts.googleapis.com/css2?family=El+Messiri&amp;family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&amp;display=swap"); */

.heading-slider h1 {
  /* font-family: "El Messiri", sans-serif !important; */
  font-family: "ElMessiri-SemiBold" !important;
  /* font-size: 1.625rem; */
  /* line-height: 1.625rem; */
}
