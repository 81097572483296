@font-face {
  font-family: FontAwesome;
  src: url(../fonts/fontawesome-custom.woff2) format("woff2");
  /* url(/FrontEndGlobalAssets/dist/735b58bd00a6a730ce3ae2ba5f36bfbe.svg#fontawesome)
      format("svg"); */
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fa-plus:before {
  content: "\F067";
}

.fa-minus:before {
  content: "\F068";
}

.fa-search:before {
  content: "\F002";
}

.fa-envelope-o:before {
  content: "\F003";
}

.fa-heart:before {
  content: "\F004";
}

.fa-star:before {
  content: "\F005";
}

.fa-star-o:before {
  content: "\F006";
}

.fa-user:before {
  content: "\F007";
}

.fa-check:before {
  content: "\F00C";
}

.fa-close:before {
  content: "\F00D";
}

.fa-remove:before {
  content: "\F00D";
}

.fa-times:before {
  content: "\F00D";
}

.fa-search-plus:before {
  content: "\F00E";
}

.fa-clock-o:before {
  content: "\F017";
}

.fa-repeat:before {
  content: "\F01E";
}

.fa-rotate-right:before {
  content: "\F01E";
}

.fa-refresh:before {
  content: "\F021";
}

.fa-flag:before {
  content: "\F024";
}

.fa-tag:before {
  content: "\F02B";
}

.fa-tags:before {
  content: "\F02C";
}

.fa-image:before {
  content: "\F03E";
}

.fa-photo:before {
  content: "\F03E";
}

.fa-picture-o:before {
  content: "\F03E";
}

.fa-pencil:before {
  content: "\F040";
}

.fa-map-marker:before {
  content: "\F041";
}

.fa-share-square-o:before {
  content: "\F045";
}

.fa-chevron-left:before {
  content: "\F053";
}

.fa-chevron-right:before {
  content: "\F054";
}

.fa-plus-circle:before {
  content: "\F055";
}

.fa-minus-circle:before {
  content: "\F056";
}

.fa-times-circle:before {
  content: "\F057";
}

.fa-check-circle:before {
  content: "\F058";
}

.fa-question-circle:before {
  content: "\F059";
}

.fa-info-circle:before {
  content: "\F05A";
}

.fa-times-circle-o:before {
  content: "\F05C";
}

.fa-check-circle-o:before {
  content: "\F05D";
}

.fa-exclamation-circle:before {
  content: "\F06A";
}

.fa-exclamation-triangle:before {
  content: "\F071";
}

.fa-warning:before {
  content: "\F071";
}

.fa-plane:before {
  content: "\F072";
}

.fa-calendar:before {
  content: "\F073";
}

.fa-chevron-up:before {
  content: "\F077";
}

.fa-chevron-down:before {
  content: "\F078";
}

.fa-twitter-square:before {
  content: "\F081";
}

.fa-camera-retro:before {
  content: "\F083";
}

.fa-comments:before {
  content: "\F086";
}

.fa-star-half:before {
  content: "\F089";
}

.fa-heart-o:before {
  content: "\F08A";
}

.fa-sign-out:before {
  content: "\F08B";
}

.fa-linkedin-square:before {
  content: "\F08C";
}

.fa-phone:before {
  content: "\F095";
}

.fa-twitter:before {
  content: "\F099";
}

.fa-arrow-circle-left:before {
  content: "\F0A8";
}

.fa-arrow-circle-right:before {
  content: "\F0A9";
}

.fa-arrow-circle-up:before {
  content: "\F0AA";
}

.fa-arrow-circle-down:before {
  content: "\F0AB";
}

.fa-globe:before {
  content: "\F0AC";
}

.fa-pinterest:before {
  content: "\F0D2";
}

.fa-pinterest-square:before {
  content: "\F0D3";
}

.fa-google-plus-square:before {
  content: "\F0D4";
}

.fa-money:before {
  content: "\F0D6";
}

.fa-caret-down:before {
  content: "\F0D7";
}

.fa-caret-up:before {
  content: "\F0D8";
}

.fa-caret-left:before {
  content: "\F0D9";
}

.fa-caret-right:before {
  content: "\F0DA";
}

.fa-envelope:before {
  content: "\F0E0";
}

.fa-rotate-left:before {
  content: "\F0E2";
}

.fa-undo:before {
  content: "\F0E2";
}

.fa-comments-o:before {
  content: "\F0E6";
}

.fa-umbrella:before {
  content: "\F0E9";
}

.fa-user-md:before {
  content: "\F0F0";
}

.fa-beer:before {
  content: "\F0FC";
}

.fa-angle-left:before {
  content: "\F104";
}

.fa-angle-right:before {
  content: "\F105";
}

.fa-angle-up:before {
  content: "\F106";
}

.fa-angle-down:before {
  content: "\F107";
}

.fa-spinner:before {
  content: "\F110";
}

.fa-circle:before {
  content: "\F111";
}

.fa-star-half-empty:before {
  content: "\F123";
}

.fa-star-half-full:before {
  content: "\F123";
}

.fa-star-half-o:before {
  content: "\F123";
}

.fa-info:before {
  content: "\F129";
}

.fa-calendar-o:before {
  content: "\F133";
}

.fa-chevron-circle-left:before {
  content: "\F137";
}

.fa-chevron-circle-right:before {
  content: "\F138";
}

.fa-chevron-circle-up:before {
  content: "\F139";
}

.fa-chevron-circle-down:before {
  content: "\F13A";
}

.fa-anchor:before {
  content: "\F13D";
}

.fa-eur:before {
  content: "\F153";
}

.fa-euro:before {
  content: "\F153";
}

.fa-gbp:before {
  content: "\F154";
}

.fa-dollar:before {
  content: "\F155";
}

.fa-usd:before {
  content: "\F155";
}

.fa-inr:before {
  content: "\F156";
}

.fa-rupee:before {
  content: "\F156";
}

.fa-cny:before {
  content: "\F157";
}

.fa-jpy:before {
  content: "\F157";
}

.fa-rmb:before {
  content: "\F157";
}

.fa-yen:before {
  content: "\F157";
}

.fa-rouble:before {
  content: "\F158";
}

.fa-rub:before {
  content: "\F158";
}

.fa-ruble:before {
  content: "\F158";
}

.fa-krw:before {
  content: "\F159";
}

.fa-won:before {
  content: "\F159";
}

.fa-youtube-square:before {
  content: "\F166";
}

.fa-youtube:before {
  content: "\F167";
}

.fa-instagram:before {
  content: "\F16D";
}

.fa-sun-o:before {
  content: "\F185";
}

.fa-arrow-circle-o-right:before {
  content: "\F18E";
}

.fa-arrow-circle-o-left:before {
  content: "\F190";
}

.fa-wheelchair:before {
  content: "\F193";
}

.fa-bank:before {
  content: "\F19C";
}

.fa-institution:before {
  content: "\F19C";
}

.fa-university:before {
  content: "\F19C";
}

.fa-language:before {
  content: "\F1AB";
}

.fa-automobile:before {
  content: "\F1B9";
}

.fa-car:before {
  content: "\F1B9";
}

.fa-cab:before {
  content: "\F1BA";
}

.fa-taxi:before {
  content: "\F1BA";
}

.fa-share-alt:before {
  content: "\F1E0";
}

.fa-bus:before {
  content: "\F207";
}

.fa-facebook-official:before {
  content: "\F230";
}

.fa-whatsapp:before {
  content: "\F232";
}

.fa-tripadvisor:before {
  content: "\F262";
}

.fa-calendar-check-o:before {
  content: "\F274";
}

.fa-map-signs:before {
  content: "\F277";
}

.fa-commenting:before {
  content: "\F27A";
}

.fa-fort-awesome:before {
  content: "\F286";
}

.fa-wheelchair-alt:before {
  content: "\F29B";
}

.fa-question-circle-o:before {
  content: "\F29C";
}

.fa-blind:before {
  content: "\F29D";
}

.fa-volume-control-phone:before {
  content: "\F2A0";
}

.fa-google-plus-circle:before {
  content: "\F2B3";
}

.fa-google-plus-official:before {
  content: "\F2B3";
}

.fa-fa:before {
  content: "\F2B4";
}

.fa-font-awesome:before {
  content: "\F2B4";
}

.fa-shower:before {
  content: "\F2CC";
}

.fa-bath:before {
  content: "\F2CD";
}

.fa-bathtub:before {
  content: "\F2CD";
}

.fa-s15:before {
  content: "\F2CD";
}

.fa-lg {
  font-size: 1.33333em;
  line-height: 0.75em;
  vertical-align: -15%;
}

.fa-2x {
  font-size: 2em;
}

.fa-3x {
  font-size: 3em;
}

.fa-4x {
  font-size: 4em;
}

.fa-5x {
  font-size: 5em;
}

.fa-fw {
  width: 1.28571em;
  text-align: center;
}

.fa-ul {
  padding-left: 0;
  margin-left: 2.14286em;
  list-style-type: none;
}

.fa-ul > li {
  position: relative;
}

.fa-li {
  position: absolute;
  left: -2.14286em;
  width: 2.14286em;
  top: 0.14286em;
  text-align: center;
}

.fa-li.fa-lg {
  left: -1.85714em;
}

.fa-border {
  padding: 0.2em 0.25em 0.15em;
  border: 0.08em solid #eee;
  border-radius: 0.1em;
}

.fa-pull-left {
  float: left;
}

.fa-pull-right {
  float: right;
}

.fa.fa-pull-left {
  margin-right: 0.3em;
}

.fa.fa-pull-right {
  margin-left: 0.3em;
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.fa.pull-left {
  margin-right: 0.3em;
}

.fa.pull-right {
  margin-left: 0.3em;
}

.fa-spin {
  -webkit-animation: fa-spin 2s linear infinite;
  animation: fa-spin 2s linear infinite;
}

.fa-pulse {
  -webkit-animation: fa-spin 1s steps(8) infinite;
  animation: fa-spin 1s steps(8) infinite;
}

@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.fa-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.fa-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.fa-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}

.fa-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  -webkit-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
  transform: scaleX(-1);
}

.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  -webkit-transform: scaleY(-1);
  -ms-transform: scaleY(-1);
  transform: scaleY(-1);
}

:root .fa-flip-horizontal,
:root .fa-flip-vertical,
:root .fa-rotate-90,
:root .fa-rotate-180,
:root .fa-rotate-270 {
  -webkit-filter: none;
  filter: none;
}

.fa-stack {
  position: relative;
  display: inline-block;
  width: 2em;
  height: 2em;
  line-height: 2em;
  vertical-align: middle;
}

.fa-stack-1x,
.fa-stack-2x {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
}

.fa-stack-1x {
  line-height: inherit;
}

.fa-stack-2x {
  font-size: 2em;
}

.fa-inverse {
  color: #fff;
}

/* @font-face {
  font-family: El Messiri;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local("El Messiri Medium"), local("ElMessiri-Medium"),
    url(../fonts/K2F3fZBRmr9vQ1pHEey6On6jFy3SZg.woff) format("woff");
  unicode-range: U+00??, U+0131, U+0152-0153, U+02bb-02bc, U+02c6, U+02da,
    U+02dc, U+2000-206f, U+2074, U+20ac, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+feff, U+fffd;
} */
